.paginacao{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-family: var(--ralewayBold);

	.page__item{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 38px;
		min-height: 38px;
		padding: 0.375rem 0.75rem;
		border-radius: 50%;
		border: 2px solid #383333;
		margin: 0 5px;
		color: #383333;
		transition: all 0.3s linear;

		&:hover,
		&.page__item--active{
			background: #383333;
			color: #FFF;
			text-decoration: none;
		}
	}
}
