#produtos-mod-2{
	color: #777;

	.subtitle-produtos{
		font-family: var(--ralewayBold);
		font-size: 24px;
		color: #000;

		svg{
			color: $primary;
			margin:0;
			vertical-align: middle;
		}
	}

	.row-produtos{
		& > *{
			display: flex;
		}
	}

	.produto{
		width: 100%;
		max-width: 350px;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;
		background: #FFF;
		box-shadow: 0 0 8px rgba(#000,.35);
		padding: 1rem;
		text-align: center;

		.foto{
			margin: -1rem -1rem 0;
		}

		.nome{
			font-size: 20px;
			font-family: var(--ralewayBold);
			text-align: center;
			padding:10px;
			color: #383333;
		}

		.descricao{
			// flex-grow: 1;
			color: #777;
			padding-bottom:10px;
		}

		.btn-area{
			text-align: center;
		}

		.btn{
			@extend .btn-primary;
			padding: 0.5rem 2rem;
			border-radius: 2rem;

			&:hover{
				box-shadow: 0 0 0 5px rgba($primary,.35);
			}
		}
	}

	#carousel{
		max-width: 460px;
		margin-left:auto;
		margin-right:auto;

		figure{
			margin-bottom:0;
		}

		.controls{
			$bg: $verde;
			position:absolute;
			bottom:0;
			right:0;
			background: $bg;
			display: flex;
			font-size: 20px;

			&:before{
				content:'';
				width:25px;
				height:100%;
				position:absolute;
				right:calc(100% - 4px);
				top:0;
				background: $bg;
				clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
			}

			a{
				display: block;
				padding:10px;
				color: #000;

				&:hover{
					color: #000;
					background: rgba(#000,.2);
					text-decoration: none;
				}
			}
		}
	}
}
