#banner{
	.carousel-indicators{
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		left: 50%;
		right: auto;
		transform: translateX(-50%);
		justify-content: flex-end;

		li{
			width: 30px;
			height: 30px;
			opacity: 1;
			border: none;
			display: flex;
			justify-content: center;
			align-items: center;
			background: none;

			&:before{
				content:'';
				display: block;
				width: 11px;
				height: 11px;
				border-radius: 50%;
				background: var(--bg, #383435);
			}

			&.active{
				--bg: #00AFEE;
			}
		}
	}
}
.msg__cookies{
	background: #FFF;
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	padding: 15px;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(#000,.8);
	width: 100%;
	z-index: 999999999;
	border: 1px solid rgba(#000,.2);

  &.d-none{
    display: none;
  }

	a{
		color: #000;
		text-decoration: underline;
	}

	.btn{
		background-color: $verde;
		font-weight: bold;
    	color: #000;

		&:hover{
		background-color: darken($verde, 10%);
		}
	}

	@media (max-width: 767px){
		max-width: calc(100% - 30px);
		font-size: 12px;
		text-align: center;

		a{
			display: inline-block;
		}

		.content{
			margin-bottom: 10px;
		}
	}

  @media (min-width: 767px){
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-column-gap: 10px;
		align-items: center;

		.btn{
			white-space: nowrap;
		}
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		max-width: 768px;
	}

  @media (min-width: 992px) and (max-width: 1199.98px) {
		max-width: 1024px;
	}

  @media (min-width: 1200px) {
		max-width: 1280px;
	}
}
.chamadas__index{
	background: $verde;
	color: #383334;

	a{
		color: inherit;

		&:hover{
			text-decoration: none;
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 30px;
		padding-bottom: 30px;
		text-align: center;

		.container > a{
			display: block;
		}

		.container > a,
		.container > .chamada__index{
			margin-top: 30px;

			width: 100%;
			max-width: 400px;
			margin-left:auto;
			margin-right:auto;
		}

		.chamada__index__icon,
		.chamada__index__titulo:after{
			margin-right: auto;
			margin-left: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.container{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}

		.chamada__index{
			padding: 45px;
			position: relative;

			&:after{
				content:'';
				width: var(--w, 0px);
				height: 6px;
				background: $primary;
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				transition: all 0.3s linear;
			}

			&:hover{
				--w: 100%
			}
		}

		.container > a{
			display: flex;
			flex-direction: column;

			.chamada__index{
				flex-grow: 1
			}
		}

		.container > a + a .chamada__index,
		.container > .chamada__index + .chamada__index{
			&:before{
				content:'';
				height: calc(100% - 90px);
				border-left: 1px solid #CDE391;
				position: absolute;
				left: 0;
				top: 45px;
			}
		}
	}
}

.chamada__index{
	display: flex;
	flex-direction: column;
}

.chamada__index__icon{
	width: 55px;
	height: 55px;
	border-radius: 50%;
	background: #CDE391;
	margin-bottom: 10px;

	lazy-image{
		transform: translateX(50%);
	}
}

.chamada__index__titulo{
	font-size: 20px;
	margin-bottom: 10px;
	font-family: var(--ralewayBold);

	&:after{
		content:'';
		display: block;
		width: 28px;
		height: 7px;
		background: currentColor;
		margin-top: 10px;
	}
}

.chamada__index__descricao{
	margin-top: auto;
}

.section__tecnologia{
	padding-top: 60px;
	padding-bottom: 60px;
	background: #F0F2F4;
	text-align: center;

	h1{
		font-family: var(--ralewayBold);
	}

	h1,
	.content__editable{
		margin-bottom: 30px;
	}

	@include media-breakpoint-up(lg) {
		.container{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas: 'TITLE TITLE' 'CONTENT CONTENT' 'SKILLS VISAO';
			grid-column-gap: 30px;
		}

		h1{
			grid-area: TITLE;
		}

		.content__editable{
			grid-area: CONTENT;
		}

		.skills{
			grid-area: SKILLS;
		}

		.missao__visao{
			grid-area: VISAO;
		}
	}
}

.skills{
	@include media-breakpoint-down(lg) {
		margin-bottom: 30px;
	}
}

.skill{
	text-align: right;
	font-family: var(--ralewayBold);

	& + .skill{
		margin-top: 15px;
	}

	.skill__bar{
		height: 8px;
		background: #FFF;
		margin: 5px 0;
		position: relative;
		box-shadow: 0 0 4px rgba(#000,.2);

		&:before{
			content:'';
			width: var(--width, 0);
			background: $verde;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			transition: width 2s linear;
		}

		&.active{
			--width: calc((var(--value, 50) / var(--max, 100)) * 100%);
		}
	}
}


.missao__visao{
	background: $verde;
	padding: 30px;
	text-align: left;

	.nav__missao__visao{
		list-style: none;
		padding: 0;
		border: 3px solid #FFF;
		display: inline-flex;
		border-radius: 2rem;
		background: #FFF;
		position: relative;
		perspective: 3px;

		&::before{
			content:'';
			width: var(--w, 33%);
			height: 100%;
			left: var(--l, 0);
			top: 0;
			background: #00AFEE;
			border-radius: 2rem;
			position: absolute;
			z-index: -1;
			transition: all 0.3s linear;
		}

		li{
			cursor: pointer;
			padding: 0.375rem 0.75rem;
			border-radius: 2rem;
			position: relative;
			transition: color 0.3s linear;
			transition-delay: 0.1s;

			&.active{
				color: #FFF;
			}
		}
	}

	h3{
		font-family: var(--ralewayBold);
		font-size: 15px;
	}
}

.section__solucoes{
	padding: 50px 0;

	hgroup{
		font-size: 16px;
		font-family: var(--ralewayBold);

		h1{
			font-size: 1em;
			color: #00AEED;
		}

		h2{
			font-size: toEm(35,16);
		}
	}

	.content-editable{
		margin-bottom: 30px;
	}

	.carousel__categorias{
		display: inline-flex;
		background: #FFF;
		border-radius: 2rem;
		box-shadow: 0 0 8px rgba(#000,.4);
		border: 4px solid #FFF;
		margin-bottom: 30px;

		a{
			color: inherit;
			display: block;
			padding: 0.375rem 0.75rem;
			border-radius: 2rem;

			& ~ a{
				margin-left: 5px;
			}

			&:hover,
			&.active{
				background: $verde;
				color: inherit;
				text-decoration: none;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.container{
			display: grid;
			grid-template-columns: calc(25% - 15px) calc(75% - 15px);
			grid-template-rows: repeat(5, auto);
			grid-template-areas:
			 'IMG TITLE'
			 'IMG CONTENT'
			 'IMG NAV'
			 'IMG CAROUSEL'
			 'IMG NOTHING'
			;
			grid-column-gap: 30px;
		}

		hgroup{
			grid-area: TITLE;
		}

		.content__editable{
			grid-area: CONTENT;
		}

		.carousel__categorias{
			grid-area: NAV;
			margin-right: auto;
		}

		.carousel__solucoes{
			grid-area: CAROUSEL;
		}

		.side__image{
			grid-area: IMG;
			align-self: flex-start;
			position: sticky;
			top: 80px;
		}
	}
}

.carousel__solucoes{
	position: relative;

	@include media-breakpoint-up(lg) {
		&:not(.owl-loaded) {
			display: flex;
			overflow: auto;

			.solucao{
				width: 100%;
				max-width: calc((100% - 90px) / 3);
				flex-shrink: 0;

				& + .solucao{
					margin-left: 30px;
				}
			}
		}
	}

	.owl-nav{
		@include media-breakpoint-down(xs) {
			text-align: center;
			margin-top: 15px;
		}

		@include media-breakpoint-up(sm) {
			position: absolute;
			bottom: 100%;
			margin-bottom: 30px;
			left: 200px;
		}
	}

	.owl-prev,
	.owl-next{
		width: 41px;
		height: 41px;
		border: 2px solid #383334 !important;
		border-radius:50%;
		margin: 0 5px;
		outline: none !important;
		transition: all 0.3s linear;

		&:hover{
			background: #383334 !important;
			color: #FFF !important;
		}

		&.disabled{
			opacity: 0.5;
			background: #383334 !important;
			color: #FFF !important;
		}
	}
}

.section__informado{
	background-color: $verde;
	padding-top: 50px;
	padding-bottom: calc((var(--contato, -200px) * -1) + 50px);
	margin-bottom: var(--contato, -200px);

	h1{
		font-family: var(--ralewayBold);
		font-size: 35px;
	}

	.btn{
		padding: 1rem 2rem;
		border-radius: 3rem;

		&:hover{
			box-shadow: 0 0 0 10px rgba($primary, .35)
		}
	}

	@include media-breakpoint-down(lg) {
		text-align:center;
	}

	@include media-breakpoint-up(xl) {
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		h1{
			width: 50%;
			margin: 0;
		}
	}
}
