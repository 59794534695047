.topo__main{

	@include media-breakpoint-up(xl) {
		.brand{
			margin-right: 50px;
		}
	}

	@include media-breakpoint-up(lg) {
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		padding-right: 15px;
		padding-left: 15px;
		margin-left: auto;
		margin-right: auto;

		display: grid;
		grid-template-columns: auto 1fr 180px min-content;
		grid-column-gap: 30px;

		.brand{
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.search,
		.redes__sociais__topo{
			align-self: center;
		}
	}

	@include media-breakpoint-down(md) {
		position: fixed;
		left: 0;
		top: 0;
		width: 250px;
		height: 100%;
		overflow: auto;
		background: #FFF;
		z-index: 1050;
		transform: translateX(var(--x, -260px));
		transition: transform 0.6s linear;

		&.topo__main--shown{
			--x: 0;
		}

		.brand{
			padding: 40px 15px;
			text-align: center;
			border-bottom: 1px solid rgba(#000,.05);
		}
	}
}
