.redes__sociais__topo{
	font-size: 18px;
	color: #383333;
	display: flex;
	justify-content: center;

	a{
		color: inherit;
		padding: 5px;
		margin: 0 5px;

		&:hover{
			color: $verde;
			text-decoration: none;
		}
	}

	@include media-breakpoint-up(lg) {
		border-left: 1px solid #B8C1CB;
		margin-left: -15px;
		padding-left: 15px;
	}

	@include media-breakpoint-down(md) {
		padding: 15px;
		flex-wrap: wrap;
		font-size: 20px;
	}
}
