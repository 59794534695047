.section__contato{
	background: #FFF;
	padding: 40px;
	width: 100%;
	max-width: #{map-get($container-max-widths, xl) - 30px};
	margin-left: auto;
	margin-right: auto;
	position: relative;
	box-shadow: 0 0 18px rgba(#000,.2);

	hgroup{
		text-align: center;
		font-family: var(--ralewayBold);
		font-size: 16px;
		margin-bottom: 30px;

		h1{
			font-size: 1em;
			color: #00AEED;
		}

		h2{
			font-size: toEm(35,16);
		}
	}

	.form-control{
		background: #F0F2F4;
		border-color: #F0F2F4;
		border-radius: 45px;
		height: 45px;
		font-family: var(--ralewayBold);
		padding-left: 1.5rem;
		padding-right: 1.5rem;

		&::placeholder{
			color: #383334;
		}
	}

	textarea.form-control{
		min-height: 76px;
	}

	.btn{
		width: 52px;
		height: 52px;
		border-radius: 50%;
		padding: 0;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		font-size: 25px;
	}

	@include media-breakpoint-up(lg) {
		z-index: 5;
		margin-bottom: var(--contato, -200px);

		form{
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-column-gap: 30px;

			.grid-4{
				grid-column: span 4;
				position: relative;
			}

			textarea.form-control{
				padding-right: 68px;
			}

			.btn{
				position: absolute;
				bottom: 27px;
				right: 8px;
			}
		}
	}
}
