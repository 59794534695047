.noticias#modelo-3{

	.h1{
		font-family: var(--ralewayBold);
		margin-bottom: 30px;

		span{
			font-weight: 900;
		}

		border-bottom:1px solid rgba(#000,.3);

		&:after{
			content:'';
			display: block;
			width: 100px;
			height: 3px;
			background: $primary;
			margin-top: 7px;
			transform: translateY(4px);
		}
	}

	.noticia-text{
		margin-bottom: 20px;

		.tag{
			color: $verde;
		}

		.titulo{
			font-size: 30px;
			line-height: 1.2;
			max-height: (1em * 3 * 1.2);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			margin-bottom: 10px;
			font-weight: 900;
			color: #444;
		}

		.descricao{
			font-size: 16px;
			line-height: 1.2;
			max-height: (1em * 1.2 * 3);
			color: #777;

			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			margin-bottom: 10px;
		}

		a:hover{
			color: #000;

			.descricao{
				color: #000;
			}
		}

		.footer{
			display: flex;
			flex-wrap:wrap;
			align-items: center;
			padding:10px 0;
			border-top:1px solid rgba(#000,0.2);
			border-bottom:1px solid rgba(#000,0.3);

			.redes-sociais{
				flex-grow:1;
				color: #777;

				a{
					display: inline-block;
					margin:0 10px;

					&:hover{
						text-decoration: none;
					}
				}
			}

			.postado{
				font-size: 12px;
				color: #777;
			}
		}
	}

	.noticia-covered{
		width: 350px;
		max-width: 100%;
		position: relative;

		.tag{
			display: inline-block;
			padding:5px 10px;
			background: #EE584D;
			margin-bottom: 10px;
		}

		.caption-noticia{
			position: absolute;
			width:100%;
			height:100%;
			left: 0;
			bottom: 0;
			z-index: 5;
			padding:10px;
			background: linear-gradient(to bottom, rgba(#000,0) 0%, rgba(#000,.8) 100%);
			color: #FFF;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		.titulo{
			line-height: 1.2;
			font-weight:900;
			font-size: 25px;
			max-height: calc(1em * 1.2 * 3);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
		}
	}

	.noticia-h{
		margin-bottom: 20px;
	}


	@include media-breakpoint-down(md) {
		.noticia-text{
			width: 350px;
			max-width: 100%;
		}

		.noticia-covered,
		.noticia-text{
			margin-right:auto;
			margin-left:auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.noticia-h:nth-child(even){
			.image-area{
				order: 1;
			}
			.text-area{
				order: 2;
			}
		}
		.noticia-h:nth-child(odd){
			.image-area{
				order: 2;
			}
			.text-area{
				order: 1;
			}
		}
	}

	.mais-lidas{
		margin-bottom: 30px;
		border-radius: 0;
		border:none;

		.card-header{
			color: #222;
			border-radius: 0;
			font-weight: 300;
			font-size: 25px;
			border: none;
			padding-bottom:0;
			border-left:5px solid $verde;
			background: none;

			span{
				font-weight: 900;
			}
		}

		.card-body{
			border-left:5px solid $verde;
		}

		.card-footer{
			padding-left:0;
			padding-right:0;
			background: none;

			.fa-angle-right-double{
				&:after,
				&:before{
					content: fa-content($fa-var-angle-right);
				}
			}

			.btn{
				background: #333333;
				color:#FFF;
				border-radius: 0;
				text-align:left;
				padding: 15px 15px;

				&:hover{
					background: darken(#333,10%)
				}
			}
		}

		a:hover{
			text-decoration: none;

			.media-body{
				color: $verde;
				text-decoration: underline;
			}
		}

		.media{
			padding: 15px 0;
			color: #777;
			border-top: 1px solid rgba(#000,.2);

			h3{
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.4;
				max-height: (1em * 1.4 * 4);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
			}

			.media-body{
				padding-bottom: 15px;
			}

			a:hover{
				color: $verde;
			}
		}

		.publicado{
			font-size: 12px;
			margin-bottom: 5px;
			line-height: 1.3;

			&:before{
				content:'';
				display: inline-block;
				height: 4px;
				width: 4px;
				border-radius: 100%;
				background: $verde;
				margin-right: 5px;
			}
		}

		.rank{
			min-width: 55px;
			font-size: 45px;
			padding-right:10px;
			padding-top: 7px;
			font-weight:900;
			color: #333333;
			transition:color 0.3s linear;
		}

		a:hover .rank{
			color: #C2D3E0
		}
	}

	.paginacao{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		a,
		span{
			margin: 0;
			border:1px solid rgba(#000,0.2);
			background: #FFF;
		}

		.active{
			background: $verde;
			color: #FFF;
		}

		*:not(.active):hover{
			background: #eee;
		}
	}

	.btn-load-more{
		@include button-variant($verde, $verde);
	}

	.noticia-detalhes{
		.header{
			margin-bottom: 40px;
			border-bottom: 3px solid #DADADA;
		}

		h1{
			border: none;
			padding-bottom: 0;
			font-weight: 900;
			font-size: 30px;
			color:#333;
			margin-bottom: 20px;

			&:after{
				display: none;
			}
		}

		.subtitulo{
			font-size: 15px;
			color: #909090;
			margin-top:0;
			margin-bottom: 20px;
			font-weight: 400;
		}

		.header-detail{
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid $primary;
			padding: 10px;

			.autor{
				flex-grow: 1;

				span{
					color: $primary;
				}

				@include media-breakpoint-up(md) {
					order: 2;
					text-align: right;
				}
			}

		}

		.redes-sociais{

			a{
				display: inline-flex;
				width: 2em;
				height: 2em;
				align-items:center;
				justify-content:center;
				border-radius: 100%;

				& + a{
					margin-left: 5px;
				}

				&:hover{
					text-decoration: none;
				}

				&.fa-facebook,
				&.fa-facebook-f{
					background: #1976d2;
					color: #FFF;
				}

				&.fa-twitter{
					background: #2196f3;
					color: #FFF;
				}

				&.fa-instagram{
					background: linear-gradient(45deg,#9c27b0 0,#ff9800 100%);
					color: #FFF;
				}
			}

			@include media-breakpoint-up(md) {
				order: 1;
			}
		}

		img{
			max-width: 100%;
			height: auto !important;
		}

		.body{
			padding-bottom: 20px;
		}

		.footer{
			padding-top:20px;
			border-top:1px dashed rgba(#000,0.3);
		}
	}

	#album-noticias {
		display: flex;

		.prev,
		.next {
			padding: 15px 10px;
			display: flex;
			align-items: center;
			background: $verde;
			color: #FFF;

			&:hover{
				text-decoration: none;
			}
		}

		.prev{
			order: 1;
		}

		.carousel-inner{
			order: 2;
		}

		.next{
			order: 3;
		}
	}

	.redes-sociais h5{
		color: #777;
		font-weight: 400;
	}

	h3.h5{
		margin-bottom: 20px;
		color: $primary;
		font-family: var(--ralewayBold);
	}
}
