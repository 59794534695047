.solucao{
	background: #F0F2F4;
	padding: 10px;
	display: flex;
	flex-direction: column;
	position: relative;

	&:after{
		content:'';
		width: var(--w, 0);
		height: 7px;
		background: #00AFEE;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		transition: all 0.3s linear;
	}

	&:hover{
		--w: 100%;
		--bgIcon: #00AFEE;
		--l: 50%;
		--x: -50%;
	}

	& > a{
		padding: 10px;
		margin: -10px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		color: inherit;

		&:hover{
			color: #383435;
			text-decoration: none;
		}
	}

	.fa-arrow-right{
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
}

.solucao__icon{
	width: 55px;
	height: 55px;
	border-radius: 50%;
	margin-bottom: 10px;
	background: #DADFE4;
	border-radius: 50%;
	position: relative;
	transform: translateX(var(--l, 0));
	transition: transform 0.3s linear;

	.solucao__icon__mask{
		transform: translateX(var(--x, 50%));
		position: absolute;
		background-color: var(--bgIcon, currentColor);
		width: 100%;
		height: 100%;
		left:0;
		top: 0;
		mask-image: var(--lazybg, none);
		transition: transform 0.3s linear;
	}
}

.solucao__nome{
	font-family: var(--ralewayBold);
	font-size: 18px;

	&:after{
		content:'';
		display: block;
		width: 30px;
		height: 7px;
		background: currentColor;
		margin-top: 5px;
	}
}

.solucao__content{
	font-size: 13px;
	margin-bottom: 20px;
}
