// .newsletter__footer{}

.newsletter__input{
	display: flex;

	.form-control,
	.btn{
		border-radius: 0;
		border-color: transparent;
	}

	.form-control{
		font-family: var(--ralewayBold);

		&::placeholder{
			color: inherit
		}
	}
}
