@import "_fontfaces";

:root{
	--raleway: 'ralewayregular', sans-serif;
	--ralewayBold: 'ralewaybold', sans-serif;
}

body{
	font-family: var(--raleway);
	font-size: 14px;
	color: #383333;
}
