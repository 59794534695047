@font-face {
    font-family: 'ralewayregular';
    src: url('#{$fonts}/raleway_regular/raleway-regular-webfont.eot');
    src: url('#{$fonts}/raleway_regular/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/raleway_regular/raleway-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/raleway_regular/raleway-regular-webfont.woff') format('woff'),
         url('#{$fonts}/raleway_regular/raleway-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/raleway_regular/raleway-regular-webfont.svg#ralewayregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'ralewaybold';
    src: url('#{$fonts}/raleway-bld/raleway-bold-webfont.eot');
    src: url('#{$fonts}/raleway-bld/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/raleway-bld/raleway-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/raleway-bld/raleway-bold-webfont.woff') format('woff'),
         url('#{$fonts}/raleway-bld/raleway-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/raleway-bld/raleway-bold-webfont.svg#ralewaybold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
