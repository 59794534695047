.rodape__wrapper{
	background-color: #F0F2F4;

	a{
		color: inherit;

		&:hover{
			color: $verde;
		}
	}

	h2{
		font-family: var(--ralewayBold);
		font-size: 15px;
		margin-bottom: 20px;
	}

	.content__editable{
		margin-bottom: 20px;
	}

	.media-icon{
		width: 30px;
	}

	.media + .media{
		margin-top: 20px;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 250px;
		background-image: var(--lazybg, none);
		background-position: center bottom;
		background-repeat: no-repeat;
		padding-bottom: 35px;

		.container{
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 30px;
		}
	}
}

.nav__footer{
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		a{
			display: inline-block;
			padding: 5px 0;
		}
	}

	li ~ li{
		margin-top: 5px;
	}
}
