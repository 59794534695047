#menu{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	a{
		display: block;
		color: inherit;

		&.active,
		&:hover{
			background: $verde;
			color: #383333;
			text-decoration: none;
		}
	}

	@include media-breakpoint-up(lg) {
		&,
		li,
		a{
			display: flex;
		}

		li{
			flex-grow: 1;
		}

		a{
			align-items: center;
			justify-content: center;
			padding: 10px;
			width: 100%;
			text-align: center;
		}
	}

	@include media-breakpoint-down(md) {
		border-bottom: 1px solid rgba(#000,.05);

		a{
			padding: 10px 15px;
		}
	}
}
