.backdrop{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background: rgba(#000, 0.7);
	animation: fadeIn 0.6s linear;

	&.hide{
		animation: fadeOut 0.6s linear;
	}
}
