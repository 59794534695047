.search{
	.input__search{
		display: flex;
		position: relative;
	}

	.form-control{
		border-color: #F0F2F4;
		background: #F0F2F4;
		border-radius: 2rem;
	}

	.btn{
		position: absolute;
		right: 2px;
		top: 2px;
		padding: 0;
		border-radius: 50px;
		height: 34px;
		width: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include button-variant(#F0F2F4, #F0F2F4);
	}
}

.mobile__search{
	position: fixed;
	left: 50%;
	top: 90px;
	width: calc(100% - 30px);
	transform: translate(-50%, var(--y, calc(-100% + -100px)));
	z-index: 1050;
	transition: all 0.6s linear;

	&.search--shown{
		--y: 0;
	}

	@include media-breakpoint-up(md) {
		display: none;
	}
}

.desktop__search{

	@include media-breakpoint-down(md) {
		display: none;
	}
}
