@import "mobile__controls";
@import "search";
@import "topo__main";
@import "menu";
@import "redes__sociais__topo";
@import "backdrop";

.topo{
	background: #FFF;
	font-family: var(--ralewayBold);
	font-size: 15px;

	@include media-breakpoint-down(md) {
		position: sticky;
		top: 0;
		z-index: 1080;
		box-shadow: 0 0 8px rgba(#000,.2);
	}
}
