.mobile__controls{
	display: grid;
	grid-template-columns: 50px 1fr 50px;
	grid-gap: 10px;
	background: #FFF;

	.brand{
		display: block;
		margin: 5px auto;
	}

	.btn{
		border-radius: 0;
		font-size: 18px;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}
