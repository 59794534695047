.creditos{
	color: #FFF;
	padding: 15px;
	background: #00AFEE;
	text-align: center;
	font-size: 12px;

	a{
		color: inherit;

		&:hover{
			color: #FFF;
		}
	}
}
