.internas{
	@include media-breakpoint-up(lg) {
		--pd: calc(var(--contato, -200px) * -1);

		padding-bottom: calc(var(--pd) + 50px);
		margin-bottom: var(--contato, -200px);
	}
}

.page__header{
	padding: 50px 0;
	background: #6D8097;
	text-align: center;
	margin-bottom: 50px;
	color: color-contrast(#6D8097);

	h1{
		font-family: var(--ralewayBold);
	}

	.breadcrumb{
		padding:0 30px 15px 30px;
		min-width: 250px;
		max-width: 100%;
		background: none;
		display: inline-flex;
		justify-content:center;
		margin-bottom: 0;
		border-radius:0;
		border-bottom: 2px solid rgba(#000,0.2);
		position: relative;

		a{
			color: inherit;
		}

		span{
			color: #FFF;
			cursor: default;
			opacity: 0.8;
		}

		&:before{
			content:'';
			position: absolute;
			left:50%;
			bottom:-2px;
			transform: translateX(-50%);
			height: 5px;
			width: 60px;
			background: $primary;
		}

		li + li:before{
			content:'/';
			display: inline-block;
			margin:0 10px;
		}
	}
}
